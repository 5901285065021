import React from 'react';
import { useTranslation } from 'react-i18next';

import { BillingPremiumCostRow } from './BillingPremiumCostRow';

import { PAYMENT_PLANS } from '../../../constants';
import { currencyFormat } from '../../../services/utils';

import './BillingPremiumCostOrca.css';

const BillingPremiumCostOrca = ({
  bottomInfo = [],
  paymentPlan = 0,
  topInfo = [],
  totalPayAmount = 0,
}) => {
  const isMonthlyPlan = paymentPlan === PAYMENT_PLANS.monthly;
  const { t } = useTranslation('insuranceScreen');

  return (
    <div className="Billing-premium-cost-orca-monthly-container">
      <h1 className="Billing-premium-cost-orca-title">
        {isMonthlyPlan ? t('billingInformation.monthlyPremiumCost')
          : t('billingInformation.annualPremiumCost')}
      </h1>

      <div className="Billing-premium-cost-orca-top-container">
        {topInfo.map((item) => {
          if (!item || !item.Amount) {
            return null;
          }

          return (
            <BillingPremiumCostRow
              key={`${item.Name} - ${item.Value}`}
              description={currencyFormat({ value: item.Amount })}
              descriptionClassName="Billing-premium-cost-orca-light-text"
              title={item.Name}
              titleClassName="Billing-premium-cost-orca-light-text"
            />
          );
        })}
      </div>

      {isMonthlyPlan && bottomInfo.length ? (
        <div className="Billing-premium-cost-orca-bottom-container">
          <div
            className={'Billing-premium-cost-orca-divider-dashed '
              + 'Margin-top-16 Margin-bottom-8'}
          />

          {bottomInfo.map((item) => {
            if (!item || !item.Amount) {
              return null;
            }

            return (
              <BillingPremiumCostRow
                key={`${item.Name} - ${item.Value}`}
                description={currencyFormat({ value: item.Amount })}
                title={item.Name}
              />
            );
          })}
        </div>

      ) : null}

      <div className="Billing-premium-cost-orca-divider Margin-top-12" />

      <BillingPremiumCostRow
        description={currencyFormat({ value: totalPayAmount })}
        styleContainer="Margin-top-16"
        title={isMonthlyPlan ? t('billingInformation.monthlyTotal')
          : t('billingInformation.annualTotal')}
      />
    </div>
  );
};

export { BillingPremiumCostOrca };
