import React from 'react';
import { useTranslation } from 'react-i18next';

import { BillingPremiumCostRow } from './BillingPremiumCostRow';

import { PAYMENT_PLANS } from '../../../constants';
import { currencyFormat } from '../../../services/utils';

import './BillingPremiumCostFigo.css';

const BillingPremiumCostFigo = ({
  annualTotal = 0,
  isGPI = false,
  monthlyInstallmentFee = '',
  monthlyPremiumCost = 0,
  monthlyTotal = 0,
  paymentPlan = 0,
}) => {
  const isMonthlyPlan = paymentPlan === PAYMENT_PLANS.monthly;
  const { t } = useTranslation('insuranceScreen');

  if (isGPI) {
    return null;
  }

  if (isMonthlyPlan) {
    return (
      <div className="Billing-premium-cost-figo-monthly-container">
        <h1 className="Billing-premium-cost-figo-title">
          {t('billingInformation.monthlyPremiumCost')}
        </h1>

        <BillingPremiumCostRow
          description={currencyFormat({ value: monthlyPremiumCost })}
          styleContainer="Margin-top-16"
          title={t('billingInformation.premiumCosts')}
        />

        {monthlyInstallmentFee ? (
          <BillingPremiumCostRow
            description={currencyFormat({ value: monthlyInstallmentFee })}
            styleContainer="Margin-top-12"
            title={t('billingInformation.monthlyInstallmentFee')}
          />
        ) : null}

        <div className="Billing-premium-cost-figo-divider Margin-top-12" />

        <BillingPremiumCostRow
          description={currencyFormat({ value: monthlyTotal })}
          descriptionClassName="Billing-premium-cost-figo-title"
          styleContainer="Margin-top-16"
          title={t('billingInformation.monthlyTotal')}
        />
      </div>
    );
  }

  return (
    <div className="Billing-premium-cost-figo-annual-container">
      <h1>{t('billingInformation.annualTotal')}</h1>

      <span>{currencyFormat({ value: annualTotal })}</span>
    </div>
  );
};

export { BillingPremiumCostFigo };
