import { call, put, select } from 'redux-saga/effects';
import platform from 'platform';
import { get, getApiV2, post } from '../services/api';
import {
  claimAdditionalStepsLoaded,
  claimPolicyByDateFailed,
  claimPolicyByDateLoaded,
  claimStepsLoaded,
  claimStepsLoadFalied,
  generalInformationLoaded,
  hasFigoClaimsFailed,
  hasFigoClaimsLoaded,
  submitClaimFailed,
  submitClaimSuccess,
} from '../actions';
import { MARKETING_CHANNEL_ID } from '../theme/theme.constants';
import app from '../app.json';
import {
  boolParse,
  doNothing,
  fileSizeToMB,
  objectToPascalCase,
} from '../services/utils';
import {
  useClaimsApiV1ForSagas,
  useClaimApiV2ForSagas,
  useClaimsApiV2ForSagas,
  useClaimsAPIV1T165578ForSagas,
} from '../services/featureFlagsForSagas';

function* loadGeneralInformationClaimbot(dispatch) {
  try {
    const useClaimApiV2 = yield useClaimApiV2ForSagas();
    const url = useClaimApiV2
      ? 'claims/bots/info'
      : 'api/Bot/GetClaimBotInfo';
    const method = useClaimApiV2 ? getApiV2 : get;

    const response = yield call(method, dispatch, url);

    if (useClaimApiV2) {
      return { Data: objectToPascalCase(response), IsValid: !!response };
    }

    return response;
  } catch {
    return null;
  }
}

function* loadInitialClaimbotSteps(dispatch) {
  try {
    const useClaimApiV2 = yield useClaimApiV2ForSagas();
    const url = useClaimApiV2
      ? 'claims/bots/steps'
      : 'api/Bot/ClaimBotFlowStepDetails';
    const method = useClaimApiV2 ? getApiV2 : post;

    const response = yield call(method, dispatch, url, {});

    if (useClaimApiV2) {
      return { IsValid: response.success, ...objectToPascalCase(response) };
    }

    return response;
  } catch {
    return null;
  }
}

export function* loadClaimSteps(dispatch) {
  const claimSteps = yield loadInitialClaimbotSteps(dispatch);
  const generalInformation = yield loadGeneralInformationClaimbot(dispatch);

  if (claimSteps && claimSteps.IsValid
    && generalInformation && generalInformation.IsValid) {
    const initialSteps = claimSteps.Data
      .map((step) => ({ ...step, InitialSteps: true }));
    yield put(claimStepsLoaded(initialSteps));
    yield put(generalInformationLoaded(generalInformation.Data));
  } else {
    yield put(claimStepsLoadFalied());
  }
}

function* doLoadAdditionClaimbotSteps({
  claimTypeId = 0,
  dispatch,
  keyName = '',
  petId = 0,
  policyNumber = 0,
}) {
  try {
    const useClaimApiV2 = yield useClaimApiV2ForSagas();
    const url = useClaimApiV2
      ? 'claims/bots/steps'
      : 'api/Bot/ClaimBotFlowStepDetails';
    const method = useClaimApiV2 ? getApiV2 : post;

    const body = useClaimApiV2 ? {
      'claim-type-id': claimTypeId,
      'key-name': keyName,
      'policy-number': policyNumber,
    } : {
      ClaimTypeId: claimTypeId,
      KeyName: keyName,
      Petid: petId,
      PolicyNumber: policyNumber,
    };

    const response = yield call(method, dispatch, url, body);

    if (useClaimApiV2) {
      return { IsValid: response.success, ...objectToPascalCase(response) };
    }

    return response;
  } catch {
    return null;
  }
}

export function* loadAdditionalClaimbotSteps(dispatch, { payload }) {
  const {
    claimTypeId,
    keyName,
    petId,
    policyNumber,
  } = payload;

  const otherSteps = yield doLoadAdditionClaimbotSteps({
    claimTypeId,
    dispatch,
    keyName,
    petId,
    policyNumber,
  });

  if (otherSteps && otherSteps.IsValid) {
    yield put(claimAdditionalStepsLoaded(otherSteps.Data));
  } else {
    yield put(claimStepsLoadFalied());
  }
}

/**
 * Gets the policy by date only for Figo policies.
 * @param {*} dispatch Dispatch.
 * @param {*} date Date of the policy.
 * @param {*} petId Id of the selected pet.
 */
export function* claimGetPolicyByDate(dispatch, { payload }) {
  const {
    date,
    petId,
  } = payload;

  const useClaimsApiV2 = yield useClaimsApiV2ForSagas();
  const apiUrl = useClaimsApiV2 ? 'claims' : 'api';

  const url = `${apiUrl}/Bot/ClaimBotPolicy?petId=${petId}&policyDate=${date}&`;

  const response = yield call(get, dispatch, url);

  if (response && response.IsValid) {
    yield put(claimPolicyByDateLoaded({
      ...response.Data,
      PolicyMarketingChannelId: MARKETING_CHANNEL_ID.figo,
    }));
  } else {
    yield put(claimPolicyByDateFailed(
      response?.Message || 'Error getting policy by date',
    ));
  }
}

function* submitClaimError({
  backendError = '',
  claimData,
  dispatch,
  frontendError = '',
  userId,
}) {
  try {
    const { os } = platform;

    const content = {
      ClaimFailError: backendError,
      DeviceInfo: `${os.family},${os.version},${app.version}`,
      FrontEndError: frontendError,
      Json: {
        ...claimData,
        AttachmentsCount: claimData.Attachments.length,
      },
      User: userId,
    };

    yield call(
      post,
      dispatch,
      'api/Bot/SubmitClaimErrorLogging',
      { content: JSON.stringify(content) },
    );
  } catch (_) {
    doNothing();
  }
}

export function* submitClaim(dispatch, { payload }) {
  const { userResponses } = payload;
  const bodyRequest = {};
  const attachments = [];

  const store = yield select(({ personalInformation }) => ({
    personalInformation,
  }));

  const { personalInformation: { userInfo: { CustomerId } } } = store;

  try {
    Object.keys(userResponses)
      .forEach((item) => {
        const userResponse = userResponses[item].value;
        const boolParsing = boolParse(userResponse);
        bodyRequest[item] = boolParsing.isBool
          ? boolParsing.value : userResponse;
      });

    if (bodyRequest.Attachments) {
      bodyRequest.Attachments.forEach((att) => attachments.push({
        DownloadBinary: att.DownloadBinary,
        Extension: att.Extension,
        Filename: att.Filename,
        Filesize: `${fileSizeToMB({ fileSize: att.size })} MB`,
      }));

      // clean model request (extra properties make new api crash)
      bodyRequest.Attachments = attachments;
    }

    const ClaimsAPIV1T165578 = yield useClaimsAPIV1T165578ForSagas();
    const legacyApi = 'api/Bot/Claim/Submit';
    const claimsApi = 'claims/bot/Claim/Submit';
    const url = ClaimsAPIV1T165578 ? claimsApi : legacyApi;
    const response = yield call(post, dispatch, url, bodyRequest);

    if (response && response.IsValid) {
      yield put(submitClaimSuccess());
    } else {
      yield call(submitClaimError, {
        backendError: response.Message || String(response.error),
        claimData: { ...bodyRequest, Attachments: attachments },
        dispatch,
        frontendError: '',
        userId: CustomerId,
      });
      yield put(submitClaimFailed());
    }
  } catch (exception) {
    yield call(submitClaimError, {
      backendError: '',
      claimData: { ...bodyRequest, Attachments: attachments },
      dispatch,
      frontendError: exception.message,
      userId: CustomerId,
    });
    yield put(submitClaimFailed());
  }
}

export function* loadHasFigoClaims(dispatch) {
  try {
    const useClaimsApiV1 = yield useClaimsApiV1ForSagas();
    const url = useClaimsApiV1
      ? 'claims/claims/HasClaimFigo'
      : 'api/PMS/Claim/GetHasClaimFigo';

    const response = yield call(get, dispatch, url);

    const hasClaimFigo = response.IsValid && response.Data;
    yield put(hasFigoClaimsLoaded(hasClaimFigo));
  } catch {
    yield put(hasFigoClaimsFailed());
  }
}
