import React from 'react';
import { useTranslation } from 'react-i18next';

import { PAYMENT_PLANS } from '../../../constants';

import './BillingInformation.css';

const RowInformation = ({
  title = '',
  description = '',
  containerClassName = '',
}) => (
  <div className={`Billing-payments-information-row ${containerClassName}`}>
    <h5 className="Billing-payments-information-row-title">
      {title}
    </h5>

    <span className="Billing-payments-information-row-description">
      {description}
    </span>
  </div>
);

const BillingInformation = ({
  paymentPlan = 0,
  name = '',
  nextBillingDate = '',
}) => {
  const { t } = useTranslation('insuranceScreen');
  const isMonthly = paymentPlan === PAYMENT_PLANS.monthly;

  return (
    <div className="Billing-payments-information-main-container">
      <h2 className="Billing-payments-information-title">
        {t('billingInformation.title')}
      </h2>

      {name ? (
        <RowInformation
          containerClassName="Margin-top-16"
          description={name}
          title={t('billingInformation.ownerName')}
        />
      ) : null}

      <div
        className={'Billing-payments-information-row '
          + 'Billing-payments-information-double-row'}
      >
        <RowInformation
          description={isMonthly ? t('billingCycleTypes.monthly')
            : t('billingCycleTypes.annual')}
          title={t('billingInformation.billingCycle')}
        />

        <RowInformation
          description={nextBillingDate}
          title={t('billingInformation.nextBillingDate')}
        />
      </div>
    </div>
  );
};

export { BillingInformation };
