import { call, put, select } from 'redux-saga/effects';
import {
  addPetFailed,
  breedDataLoaded,
  clearUserCache,
  deletePetFailed,
  microchipDataLoaded,
  microchipFailed,
  microchipSaved,
  petAdded,
  petDeleted,
  petEdited,
  petPhotoRemoved,
  petProfileFailed,
  petProfileLoadAttempt,
  petProfileLoaded,
  removePetPhotoFailed,
} from '../actions';
import { PET_TYPE_ID } from '../constants';
import { del, get, post } from '../services/api';
import {
  useCustomerApi150617V1ForSagas,
} from '../services/featureFlagsForSagas';
import { getPolicySummary } from './policies';
import { sleep } from '../services/utils';

export function* addPet(dispatch, { payload }) {
  const { petToAdd } = payload;

  const response = yield call(
    post,
    dispatch,
    'api/Pets/AddPetResponsePetId',
    petToAdd,
  );

  if (response.success && response.IsValid) {
    yield put(petAdded({ addedPetId: response.Data.PetId }));
    // wait for lottie animation end
    yield call(sleep, 1000);
    yield call(getPolicySummary, dispatch);
  } else {
    yield put(addPetFailed());
  }
}

export function* loadBreedList(dispatch) {
  const petBreedsUrlBase = 'api/Pets/Breeds';

  const dogBreeds = yield call(
    get,
    dispatch,
    `${petBreedsUrlBase}/${PET_TYPE_ID.DOG}`,
  );

  const catBreeds = yield call(
    get,
    dispatch,
    `${petBreedsUrlBase}/${PET_TYPE_ID.CAT}`,
  );

  if (dogBreeds.success && dogBreeds.IsValid
    && catBreeds.success && catBreeds.IsValid) {
    yield put(breedDataLoaded({
      catBreeds: catBreeds.Data,
      dogBreeds: dogBreeds.Data,
    }));
  }
}

export function* loadMicrochipData(dispatch, { payload }) {
  const response = yield call(
    get,
    dispatch,
    `api/Microchip/${payload.petId}`,
  );

  const companiesResponse = yield call(
    get,
    dispatch,
    'api/Microchip/All',
  );

  if (response.success && response.IsValid) {
    yield put(microchipDataLoaded({
      companies: companiesResponse.Data,
      data: response.Data ? response.Data : {
        MicrochipId: 1,
        OrderId: payload.petId,
      },
      petId: payload.petId,
    }));
  }
}

export function* saveMicrochip(dispatch, { payload }) {
  const response = yield call(
    post,
    dispatch,
    'api/Microchip',
    payload,
  );

  if (response.success && response.IsValid) {
    yield put(microchipSaved(payload));
  } else {
    yield put(microchipFailed());
  }
}

export function* editPet(dispatch, { payload }) {
  const useCustomerPets = yield useCustomerApi150617V1ForSagas();
  const urlPref = useCustomerPets ? 'customer' : 'api';

  const response = yield call(
    post,
    dispatch,
    `${urlPref}/Pets/Pet`,
    payload,
  );

  if (response.success && response.IsValid) {
    dispatch(clearUserCache());
    yield put(petEdited());
    yield call(getPolicySummary, dispatch);
  } else {
    yield put(addPetFailed());
  }
}

export function* getPetProfile(dispatch) {
  yield put(petProfileLoadAttempt());
  const store = yield select(({ myPets }) => (myPets));
  const useCustomerPets = yield useCustomerApi150617V1ForSagas();
  const urlPref = useCustomerPets ? 'customer' : 'api';

  const { selectedPetId } = store;

  if (!selectedPetId) {
    return;
  }

  const response = yield call(
    get,
    dispatch,
    `${urlPref}/Pets/Pet/${selectedPetId}`,
  );

  if (response.success && response.IsValid) {
    yield put(petProfileLoaded(response.Data));
  } else {
    yield put(petProfileFailed());
  }
}

export function* deletePet(dispatch, { payload }) {
  const response = yield call(
    post,
    dispatch,
    `api/Pets/Remove?orderId=${payload}`,
    {},
  );

  if (response.success && response.IsValid) {
    yield put(clearUserCache());
    yield put(petDeleted());
    yield call(getPolicySummary, dispatch);
  } else {
    yield put(deletePetFailed());
  }
}

export function* removePetPhoto(dispatch, { payload }) {
  const { petProfileId } = payload;
  const response = yield call(
    del,
    dispatch,
    `api/Pet/${petProfileId}/Picture`,
  );

  if (response.IsValid) {
    yield put(petPhotoRemoved());
    yield put(clearUserCache());
  } else {
    yield put(removePetPhotoFailed());
  }
}
