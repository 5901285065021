import { put, call, all } from 'redux-saga/effects';
import {
  del,
  delApiV2,
  get,
  getApiV2,
  getV2,
  patchApiV2,
  post,
  postApiV2,
  postBlob,
} from '../services/api';
import {
  changeNameFailed,
  changeNameSuccess,
  createFolderFailed,
  createFolderSuccess,
  deleteFailed,
  deleteSuccess,
  errorViewFile,
  filesDownloaded,
  filesMarkedAsRead,
  filesMoved,
  filesUploaded,
  fileToUploadUpdated,
  folderLoaded,
  folderMoved,
  folderStructureLoaded,
  loadFolderFailed,
  markFilesAsReadAttempted,
  moveFilesFailed,
  recentFilesLoaded,
  recentFilesLoadFailed,
  recentFilesRequested,
  showAlert,
  showedViewFile,
  tagPetFileFailed,
  tagPetFileSuccess,
  uploadFiles,
} from '../actions';
import { READ_FILE_SOURCE } from '../constants';
import {
  delay,
  downloadFileInPage,
  objectToPascalCase,
} from '../services/utils';
import {
  useCustomerInboxDocsApiV2ForSagas,
} from '../services/featureFlagsForSagas';

export function* loadFolderContent(dispatch, { payload }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const url = useCustomerInboxDocsApiV2
    ? `customer/folders/${payload}`
    : `api/InboxDocs/GetFolders/${payload}`;

  const response = yield call(
    useCustomerInboxDocsApiV2 ? getApiV2 : get,
    dispatch,
    url,
  );

  if (response.IsValid || response.success) {
    const responseData = response.Data || objectToPascalCase(response.data);
    yield put(folderLoaded(responseData));
  } else {
    yield put(loadFolderFailed(response.Message || ''));
  }
}

export function* getRecentFiles(dispatch) {
  yield put(recentFilesRequested());
  const useCustomerInboxDocsApiV2 =
    yield useCustomerInboxDocsApiV2ForSagas();
  const url = useCustomerInboxDocsApiV2
    ? 'customer/files'
    : 'api/InboxDocs/GetRecentFiles';

  const response = yield call(
    useCustomerInboxDocsApiV2 ? getApiV2 : get,
    dispatch,
    url,
  );

  if (response.IsValid || response.success) {
    const responseData = response.Data || objectToPascalCase(response.data);
    yield put(recentFilesLoaded(responseData));
  } else {
    yield put(recentFilesLoadFailed(response.Message));
  }
}

export function* markFilesAsRead(dispatch, { payload }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const url = useCustomerInboxDocsApiV2
    ? 'customer/files/batch'
    : 'api/InboxDocs/MarkReadMultipleFiles';
  const { filesIds, source } = payload;

  if (source === READ_FILE_SOURCE.toolbar) {
    yield put(markFilesAsReadAttempted());
  }

  const method = useCustomerInboxDocsApiV2 ? patchApiV2 : post;

  yield call(
    method,
    dispatch,
    url,
    filesIds.map((fileId) => (useCustomerInboxDocsApiV2 ? ({
      id: fileId,
      isRead: true,
    }) : ({
      FileId: fileId,
      IsRead: true,
    }))),
  );

  if (source !== READ_FILE_SOURCE.claims) {
    yield put(filesMarkedAsRead(filesIds));
  }
}

export function* moveFiles(dispatch, { payload }) {
  const { destinationFolderId, filesIds } = payload;
  // TODO: Not ready v2, but they will be unified in the same flag soon
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const service = useCustomerInboxDocsApiV2 ? 'customer' : 'api';
  const response = yield call(
    post,
    dispatch,
    `${service}/InboxDocs/MoveFiles`,
    {
      FileIds: filesIds,
      MoveFolderId: destinationFolderId,
    },
  );

  if (response.IsValid) {
    yield put(filesMoved());
  } else {
    yield put(moveFilesFailed());
  }
}

export function* moveFolder(dispatch, { payload }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const url = useCustomerInboxDocsApiV2
    ? 'customer/folders'
    : 'api/InboxDocs/MoveFolder';
  const { destinationFolderId, folderIdToMove } = payload;

  const requestV1 = {
    FolderIdToMove: folderIdToMove,
    MoveToParentForumTopicId: destinationFolderId,
  };

  const requestV2 = {
    folderId: folderIdToMove,
    parentFolderId: destinationFolderId,
  };

  const response = yield call(
    useCustomerInboxDocsApiV2 ? patchApiV2 : post,
    dispatch,
    url,
    useCustomerInboxDocsApiV2 ? requestV2 : requestV1,
  );

  if (response.IsValid || response.ok) {
    yield put(folderMoved());
  } else {
    put(showAlert({ type: 'error' }));
  }
}

export function* loadFolderStructure(dispatch) {
  const useCustomerApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const url = useCustomerApiV2
    ? 'customer/folders'
    : 'api/InboxDocs/GetAllFoldersByUser';
  const method = useCustomerApiV2 ? getApiV2 : get;

  const response = yield call(
    method,
    dispatch,
    url,
  );

  if (response.IsValid || response.success) {
    const responseData = useCustomerApiV2
      ? objectToPascalCase(response.data) : response.Data;
    yield put(folderStructureLoaded(responseData));
  }
}

export function* changeName(dispatch, { payload }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const { isFolder, itemId, newName } = payload;
  const api = useCustomerInboxDocsApiV2 ? 'customer' : 'api';
  /* TODO: RenameFiles v2 not ready */
  let url = `${api}/InboxDocs/Rename${isFolder ? 'Folder' : 'File'}`;
  let method = post;

  let request = isFolder
    ? {
      FolderId: itemId,
      NewFolderName: newName.trim(),
    } : {
      FileId: itemId,
      NewFileName: newName.trim(),
    };

  if (useCustomerInboxDocsApiV2 && isFolder) {
    url = 'customer/folders';
    method = patchApiV2;
    request = {
      FolderId: itemId,
      FolderName: newName.trim(),
    };
  }

  const response = yield call(
    method,
    dispatch,
    url,
    request,
  );

  if (response.IsValid || response.success) {
    yield put(changeNameSuccess());
  } else {
    const errorMessage = response.Message || response.error.detail;
    yield put(changeNameFailed(errorMessage || 'Unexpected error.'));
  }
}

function* downloadMedia(dispatch, resourceId) {
  const url = `api/Media/Download/${resourceId}`;
  const response = yield call(getV2, dispatch, url);
  return response;
}

export function* openMediaFileToBlob(dispatch, { payload }) {
  const response = yield downloadMedia(dispatch, payload);

  if (window && response.IsValid) {
    window.open(response.Data.MediaUrl, '_blank');
  } else {
    yield put(showAlert({ type: 'error' }));
  }
  return response;
}

export function* downloadFiles(dispatch, { payload }) {
  const {
    singleFile = false,
    singleFileInfo = {},
    fileIds,
    folderIds,
  } = payload;

  const timestamp = new Date().toISOString().split('T')[0];
  const zipName = `MyPetCloud_download_${timestamp}.zip`;

  if (singleFile) {
    const { Id } = singleFileInfo;
    const responseUrlBlob = yield downloadMedia(dispatch, Id);

    if (responseUrlBlob.IsValid) {
      yield downloadFileInPage({
        fileName: singleFileInfo.Name,
        fileUrl: responseUrlBlob.Data.MediaUrl,
      });
    } else {
      yield put(showAlert({ type: 'error' }));
    }

    yield put(filesDownloaded());
    return;
  }

  const url = 'api/InboxDocs/DownloadFiles';

  const request = {
    FileIds: fileIds || [],
    FolderIds: folderIds || [],
  };

  const response = yield call(
    postBlob,
    dispatch,
    url,
    request,
  );

  if (response.success) {
    const newBlob = new Blob([response.blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);

    downloadFileInPage({ blobUrl, fileName: zipName });
  } else {
    yield put(showAlert({ type: 'error' }));
  }

  yield put(filesDownloaded());
}

export function* tagPetFile(dispatch, { payload }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();

  const url = useCustomerInboxDocsApiV2
    ? 'customer/files'
    : 'api/InboxDocs/File/Tagging';

  const method = useCustomerInboxDocsApiV2 ? patchApiV2 : post;

  const { currentFolderId, fileId, petIdList } = payload;

  const request = useCustomerInboxDocsApiV2 ? {
    folderId: currentFolderId,
    id: fileId,
    petIds: petIdList,
  } : {
    FileId: fileId,
    PetsIds: petIdList,
  };

  const response = yield call(
    method,
    dispatch,
    url,
    request,
  );

  if (response.success || (response.IsValid && response.Data)) {
    yield put(tagPetFileSuccess());
  } else {
    yield put(tagPetFileFailed(response.Message || 'Unexpected error.'));
  }
}

export function* createFolder(dispatch, { payload }) {
  const { parentId, name } = payload;
  const data = { folderName: name, parentFolderId: parentId };
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const url = useCustomerInboxDocsApiV2
    ? 'customer/folders'
    : 'api/InboxDocs/CreateFolder';

  const response = yield call(
    useCustomerInboxDocsApiV2 ? postApiV2 : post,
    dispatch,
    url,
    data,
  );

  if (response.IsValid || response.success) {
    yield put(createFolderSuccess());
  } else {
    const errorMessage = response.Message || response.error.detail;
    yield put(createFolderFailed(errorMessage || 'Unexpected error.'));
  }
}

export function* deleteFiles(dispatch, { payload }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();

  const { fileIds } = payload;

  const url = useCustomerInboxDocsApiV2
    ? `customer/files/${encodeURI(fileIds.join())}`
    : 'api/InboxDocs/DeleteFiles';

  const method = useCustomerInboxDocsApiV2 ? delApiV2 : del;

  const request = useCustomerInboxDocsApiV2 ? {}
    : {
      FileIds: fileIds,
    };

  const response = yield call(
    method,
    dispatch,
    url,
    request,
  );

  if (response.IsValid || response.success) {
    yield put(deleteSuccess());
  } else {
    yield put(deleteFailed(response.Message || 'Unexpected error.'));
  }
}

export function* deleteFolder(dispatch, { payload }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const { folderId } = payload;

  const url = useCustomerInboxDocsApiV2
    ? `customer/folders/${folderId}`
    : `api/InboxDocs/DeleteFolder/${folderId}`;

  const response = yield call(
    useCustomerInboxDocsApiV2 ? delApiV2 : del,
    dispatch,
    url,
  );

  if (response.IsValid || response.success) {
    yield put(deleteSuccess());
  } else {
    yield put(deleteFailed(response.Message || 'Unexpected error.'));
  }
}

function* simulateLoading({ fileKey, progress }) {
  if (progress < 70) {
    yield call(delay, 50);
    yield put(fileToUploadUpdated({
      fileKey,
      newValues: {
        errorMsg: '',
        percentage: progress,
      },
    }));
    yield call(simulateLoading, {
      fileKey,
      progress: progress + 10,
    });
  }
}

function* uploadFile({ dispatch, fileKey, request }) {
  const useCustomerInboxDocsApiV2 = yield useCustomerInboxDocsApiV2ForSagas();
  const url = useCustomerInboxDocsApiV2
    ? 'customer/files'
    : 'api/InboxDocs/CreateFile';
  const response = yield call(
    useCustomerInboxDocsApiV2 ? postApiV2 : post,
    dispatch,
    url,
    request,
  );
  yield call(simulateLoading, { fileKey, progress: 1 });
  if (response.IsValid || response.success) {
    yield put(fileToUploadUpdated({
      fileKey,
      newValues: {
        hideDelete: true,
        percentage: 100,
      },
    }));
  } else {
    yield put(fileToUploadUpdated({
      fileKey,
      newValues: {
        valid: false,
      },
    }));
  }
}

export function* uploadFilesPerBatch(dispatch, { payload }) {
  const { files, orderIds, folderId, batchSize, currentBatch } = payload;
  if (files.length) {
    const batch = files.slice(0, batchSize);
    yield all(batch.map((file) => {
      const request = {
        ContentType: file.contentType,
        Extension: file.extension,
        FileContent: file.binary,
        FileName: file.filename,
        FolderId: folderId,
        OrderIds: orderIds,
      };
      return call(uploadFile, {
        dispatch,
        fileKey: file.key,
        request,
      });
    }));
    const nextBatch = files.slice(batchSize);
    yield put(uploadFiles({
      batchSize,
      currentBatch: currentBatch + 1,
      files: nextBatch,
      folderId,
      orderIds,
    }));
  } else {
    yield put(filesUploaded());
  }
}

export function* loadBlobFile(dispatch, { payload }) {
  const { Id } = payload;
  const response = yield downloadMedia(dispatch, Id);

  if (response.IsValid) {
    const { MediaUrl } = response.Data;

    yield put(showedViewFile({
      ...payload,
      Metadata: {
        ...payload.Metadata,
        Url: MediaUrl,
      },
    }));
  } else {
    yield put(errorViewFile());
  }
}
