import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import figoPetTagIcon from '../../../assets/myPets/cards/figo-pet-tag.svg';
import insuranceIcon from '../../../assets/myPets/cards/insurance.svg';
import microchipIcon from '../../../assets/myPets/cards/microchip.svg';
import myVetsIcon from '../../../assets/myPets/cards/my-vets.svg';
import {
  billingPaymentReseted,
  clearPolicyNotifications,
  figoTagModalShown,
  loadMicrochipData,
  loadPetProfile,
  loadPoliciesOverview,
  loadPolicyDetails,
  microchipModalVisible,
  petSelected,
  setSelectedPetId,
  setSelectedPetIndex,
  temporaryTokenReset,
} from '../../../actions';
import {
  goToGetQuotesD2C,
} from '../../../services/utils';
import {
  PET_GENDER,
  PET_TYPE_ID,
} from '../../../constants';
import { useMixpanel, usePMS } from '../../../hooks';
import { EV1, EV15 } from '../../../services/mixpanel';
import * as routes from '../../navigation/routes.constants';

import { PetProfileInfoCardMyPets } from './PetProfileInfoCardMyPets';
import FigoLottie from '../../common/FigoLottie';
import loadingSpinnerBlue
from '../../../assets/lottieFiles/loading-blue.json';
import { PetProfileProgressMyPets } from './PetProfileProgressMyPets';
import { YoungPetModal } from '../../insurance/YoungPetModal';
import { PetProfileCompleted } from '../PetProfileCompleted';
import { MyOwnPetsValidation } from './MyOwnPetsValidation';
import { MyVetsComponent } from '../myVets/MyVetsComponent';
import { MicrochipScreen } from '../microchip/MicrochipScreen';
import { MicroChipModal } from './MicroChipModal';
import { PetTagScreen } from '../petTag/PetTagScreen';
import { PetProfileCard } from './PetProfileCard';
import { PetTagModal } from '../petTag/PetTagModal';
import { usePolicyBrand } from '../../../hooks/usePolicyBrand';
import './PetProfileScreenMyPets.css';

const PetProfileScreenMyPets = ({
  handleOnAddFamilyCoverClick,
  handleOnEditPetClick,
}) => {
  const { t } = useTranslation('myPets');
  const dispatch = useDispatch();
  const history = useHistory();
  const { sendEvent } = useMixpanel();
  const { path, url } = useRouteMatch();
  const { pms } = usePMS();
  const petInfoRef = useRef();
  const store = useSelector(
    ({
      figoPetTag,
      myPets,
      personalInformation,
      policies,
      session,
    }) => ({
      figoPetTag,
      myPets,
      personalInformation,
      policies,
      session,
    }), shallowEqual,
  );

  const {
    figoPetTag: {
      data: { TagNumber },
      isFigoTagModalVisible,
    },
    myPets: { microchipData, petProfile, petProfileLoading, selectedPetId },
    policies: {
      allPets,
      isYoungPetModalVisible,
      loading,
      multibrandPolicies,
      policiesOverView,
    },
    personalInformation: {
      pets,
      petsLoading,
      profileLoading,
      userInfo,
    },
    session: { isImpersonate, impersonatingAdminEmail, publicToken },
  } = store;

  useEffect(() => {
    if (selectedPetId) {
      dispatch(loadPetProfile());
      dispatch(loadMicrochipData({ petId: selectedPetId }));
    }
  }, [selectedPetId]);

  const profileVisited = useCallback(() => {
    if (!petProfileLoading && selectedPetId
      && Object.keys(petProfile).length
      && Object.keys(userInfo).length) {
      const {
        CustomerGuid,
        CustomerId,
        petOwnerFollowed,
      } = petProfile;
      const { LoginEmail, SocialInfo: { Username } } = userInfo;

      sendEvent(EV15({
        customerEmail: LoginEmail,
        customerGuid: CustomerGuid,
        customerName: Username,
        followStatus: petOwnerFollowed,
        ownPet: 'Yes',
        petId: selectedPetId,
        petOwnerId: CustomerId,
      }));
    }
  }, [selectedPetId, petProfile, petProfileLoading, userInfo]);

  useEffect(profileVisited, []);

  const pet = useMemo(() => {
    if (!pets) {
      return {};
    }

    return pets
      .find((element) => element.Id === Number(selectedPetId));
  }, [pets, selectedPetId]);

  const petPolicy = useMemo(() => {
    const foundPet = allPets
      .find((petValue) => petValue.PetId === Number(selectedPetId));

    if (foundPet && pet && foundPet.policies) {
      const foundPolicy = foundPet.policies
        .find((policy) => policy.Number === pet.PolicyNumber) || {};
      if (foundPolicy && policiesOverView) {
        const policyOverview = policiesOverView
          .find((value) => value.PetInformation.PolicyNumber
            === pet.PolicyNumber) || {};
        return {
          ...foundPolicy,
          ...policyOverview,
        };
      }
    }

    return null;
  }, [allPets, pet, policiesOverView, selectedPetId]);

  const { policyBrandName } =
    usePolicyBrand({ policyNumber: pet?.PolicyNumber });

  const isFigo = useMemo(() => !!(petPolicy && petPolicy.Number),
    [petPolicy]);

  const familyMembers = useMemo(() => {
    if (!pets) {
      return [];
    }

    return pets
      .reduce((petList, element) => {
        if (element.Id !== Number(selectedPetId)) {
          petList.push({
            petId: element.Id,
            petName: element.Name,
            petProfileImage: element.ImageUrl,
            petType: element.PetType,
          });
        }
        return petList;
      }, []);
  }, [pets, selectedPetId]);

  useEffect(() => {
    if (pet && pet.PolicyNumber) {
      dispatch(loadPolicyDetails(pet.PolicyNumber));
    }

    if (isFigo) {
      dispatch(loadPoliciesOverview());
    }
  }, [pet, dispatch, isFigo]);

  useEffect(() => {
    if (publicToken) {
      goToGetQuotesD2C({
        impersonate: isImpersonate,
        impersonatingAdminEmail,
        LoginEmail: userInfo.LoginEmail,
        publicToken,
      });

      dispatch(temporaryTokenReset());
    }
  }, [
    dispatch,
    userInfo.LoginEmail,
    publicToken,
  ]);

  function handleAddPetPhotoClick() {
    if (petInfoRef.current) {
      petInfoRef.current.handleAddPhoto();
    }
  }

  function handleAddMicrochipClick() {
    dispatch(microchipModalVisible({ isVisible: true }));
  }

  function handleOnClickInsurance() {
    const arrayData = isFigo ? allPets : multibrandPolicies || [];
    const petIndex = arrayData?.findIndex((data) => data.PetId
      === petProfile.Id);
    dispatch(petSelected(petIndex));
    dispatch(setSelectedPetId(Number(petProfile.Id)));
    dispatch(clearPolicyNotifications());
    dispatch(setSelectedPetIndex(petIndex));
    dispatch(billingPaymentReseted());
    const source = window.location.pathname;
    sendEvent(EV1({ pms, policyBrand: policyBrandName, source }));
    history.push(`${routes.INSURANCE}/${petProfile.Id}`);
  }

  const completeProfileSteps = useMemo(() => {
    const steps = [
      {
        isComplete: true,
        name: t('completeProfile.addPetDetails'),
      },
      {
        isComplete: !!petProfile.ImageUrl,
        name: t('completeProfile.addPetPhoto'),
        onClick: handleAddPetPhotoClick,
      },
      {
        isComplete: !!store.personalInformation.profile.CoverPicture,
        name: t('completeProfile.addFamilyPhoto'),
        onClick: handleOnAddFamilyCoverClick,
      },
      {
        isComplete: !!petProfile.MicrochipDto,
        name: t('completeProfile.addMicrochip'),
        onClick: handleAddMicrochipClick,
      },
      {
        isComplete: !!petProfile.Description,
        name: t('completeProfile.addPetBio'),
        onClick: () => handleOnEditPetClick({ isDescription: true }),
      },
    ];

    if (isFigo) {
      steps.push({
        isComplete: !!petProfile.PetTagInfoDto?.TagNumber,
        name: t('completeProfile.addFigoTag'),
        onClick: () => dispatch(figoTagModalShown()),
      });
    }

    const stepsSorted = steps.sort((prevStp, nextStp) => {
      if (prevStp.isComplete && !nextStp.isComplete) {
        return -1;
      }

      if (!prevStp.isComplete && nextStp.isComplete) {
        return 1;
      }

      return 0;
    });

    return stepsSorted;
  }, [
    dispatch,
    figoTagModalShown,
    isFigo,
    microchipData,
    petProfile.Description,
    petProfile.ImageUrl,
    petProfile.MicrochipDto,
    petProfile.PetTagInfoDto,
    store.personalInformation.profile.CoverPicture,
    TagNumber,
  ]);

  function getPercentageProgress() {
    const completedStepsList = completeProfileSteps
      .map((step) => step.isComplete)
      .filter((isCompleted) => isCompleted);

    return Math
      .trunc((completedStepsList.length / completeProfileSteps.length) * 100);
  }

  const renderMyPetsProgressContent = () => {
    const currentPercentageProgress = getPercentageProgress();
    const isProfileCompleted = currentPercentageProgress === 100;

    if (isProfileCompleted) {
      return <PetProfileCompleted petName={petProfile.Name} />;
    }

    return (
      <PetProfileProgressMyPets
        percentageComplete={currentPercentageProgress}
        petName={petProfile.Name}
        steps={completeProfileSteps}
      />
    );
  };

  const CardGrid = () => {
    const getContent = () => {
      const cardContent = [
        {
          href: '',
          iconSrc: insuranceIcon,
          onClick: handleOnClickInsurance,
          paragraph: t('completeProfile.insuranceParagraph'),
          title: t('completeProfile.insuranceTitle'),
        },
        {
          href: `${url}${routes.MY_VETS}`,
          iconSrc: myVetsIcon,
          paragraph: t('completeProfile.myVetsParagraph'),
          title: t('completeProfile.myVetsTitle'),
        },
        {
          href: `${url}${routes.MICROCHIP}`,
          iconSrc: microchipIcon,
          paragraph: t('completeProfile.microchipParagraph'),
          title: t('completeProfile.microchipTitle'),
        },
      ];

      if (isFigo) {
        cardContent.splice(3, 0, {
          href: `${url}${routes.PET_TAG}`,
          iconSrc: figoPetTagIcon,
          paragraph: t('completeProfile.figoPetTagParagraph'),
          title: t('completeProfile.figoPetTagTitle'),
        });
      }

      return cardContent;
    };

    return (
      <div className="My-pets-profile-grid-container">
        {getContent().map((item) => (
          <PetProfileCard
            key={item.title}
            href={item.href}
            iconSrc={item.iconSrc}
            onClick={item.onClick}
            paragraph={item.paragraph}
            title={item.title}
          />
        ))}
      </div>
    );
  };

  const renderPetProfile = () => {
    if (
      petsLoading
      || profileLoading
      || loading
      || petProfileLoading
    ) {
      return (
        <div className="My-pets-loading-container">
          <FigoLottie
            animationData={loadingSpinnerBlue}
            height={50}
            width={50}
          />
        </div>
      );
    }

    return (
      <div className="My-pets-profile-container">
        <PetProfileInfoCardMyPets
          ref={petInfoRef}
          city={store.personalInformation.profile.City}
          familyMembers={familyMembers}
          familyPicture={store.personalInformation.profile.CoverPicture}
          handleOnAddFamilyCoverClick={handleOnAddFamilyCoverClick}
          handleOnEditPetClick={handleOnEditPetClick}
          petAge={petProfile.Age || '-'}
          petBio={petProfile.Description || null}
          petBirthday={petProfile.DisplayDOB?.split('T')[0] || null}
          petBreed={petProfile.BreedName || '-'}
          petBreedId={Number(petProfile.BreedId)}
          petGender={petProfile.Gender || PET_GENDER.MALE}
          petId={petProfile.Id}
          petName={petProfile.Name || ''}
          petType={petProfile.Type || PET_TYPE_ID.DOG}
          petWeight={petProfile.Weight || null}
          profilePicture={petProfile.ImageUrl || null}
        />

        <div className="My-pets-cards-container">
          {renderMyPetsProgressContent()}

          <CardGrid />
        </div>

        <YoungPetModal isVisible={isYoungPetModalVisible} />

        <MicroChipModal />

        <PetTagModal isVisible={isFigoTagModalVisible} petId={selectedPetId} />
      </div>
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <MyOwnPetsValidation />

          {renderPetProfile()}
        </Route>

        <Route path={`${path}${routes.MY_VETS}`}>
          <MyVetsComponent petName={petProfile.Name} />
        </Route>

        <Route path={`${path}${routes.MICROCHIP}`}>
          <MicrochipScreen />

          <MicroChipModal />
        </Route>

        <Route path={`${path}${routes.PET_TAG}`}>
          <PetTagScreen />

          <MicroChipModal />
        </Route>
      </Switch>
    </>
  );
};

export { PetProfileScreenMyPets };
