import React from 'react';

import './BillingPremiumCostRow.css';

const BillingPremiumCostRow = ({
  description = '',
  descriptionClassName = '',
  styleContainer = '',
  title = '',
  titleClassName = '',
}) => (
  <div className={`Billing-premium-cost-row ${styleContainer}`}>
    <span className={`Billing-premium-cost-row-title ${titleClassName}`}>
      {title}
    </span>

    <span
      className={`Billing-premium-cost-row-description ${descriptionClassName}`}
    >
      {description}
    </span>
  </div>
);

export { BillingPremiumCostRow };
