import React, {
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../common/ModalContainer';
import { FigoSelectSearchable } from './FigoSelectSearchable';
import {
  PET_GENDER,
  PET_TYPE_ID,
} from '../../constants';
import closeIcon from '../../assets/close-button.svg';
import infoIcon from '../../assets/information-icon.svg';
import arrowIcon from '../../assets/back-arrow.svg';
import { ReactComponent as CatIcon } from '../../assets/sideMenu/cat.svg';
import { ReactComponent as DogIcon } from '../../assets/sideMenu/dog.svg';

import { AgeInputSelector } from './AgeInputSelector';
import { GroupButtonSelector } from '../common/petCloud/GroupButtonSelector';
import { ActionButton } from '../common/petCloud/ActionButton';

import ScrollableDiv from '../common/ScrollableDiv';
import { PetCloudInput } from '../common/petCloud';

import { useAddAPetModal } from './hooks/useAddAPetModal';

import { useMixpanel } from '../../hooks';

import { EV10 } from '../../services/mixpanel';

import './AddAPetModal.css';

const PET_BIO_MAX_LENGTH = 2000;

const AddAPetModal = ({
  isGPI = false,
  modalAddPetVisible,
  onCloseModalAddPet,
  petInformation = null,
  onDelete = () => { },
  onContactUsClick = () => { },
}) => {
  const { sendEvent } = useMixpanel();
  const petNameRef = useRef();
  const petGenderRef = useRef();
  const petWeightRef = useRef();
  const petBioRef = useRef();
  const { t } = useTranslation('myPets');
  const isEditMode = !!petInformation;
  const modalTitle = isEditMode
    ? t('AddAPetModal.editPetTitle') : t('AddAPetModal.addPetTitle');
  const isPetInsured = petInformation && petInformation.IsInsured;
  function handleModalAddPetClose() {
    onCloseModalAddPet(false);
  }

  const {
    handlePetBreedSelectionChanged,
    handlePetGenderSelectionChange,
    handlePetNameChanged,
    handlePetNameOnBlur,
    handlePetsBioChange,
    handlePetTypeSelectedChanged,
    handlePetWeightChanged,
    handlePetWeightOnBlur,
    handlePetWeightOnFocus,
    handleSavePet,
    myPets,
    onBirthdaySelectorBlur,
    onDatePickerChange,
    petBio,
    petBirthdayError,
    petBirthdaySelected,
    petBreedError,
    petBreedList,
    petBreedSelected,
    petGenderError,
    petGenderSelected,
    petName,
    petNameError,
    petTypeError,
    petTypeSelected,
    petWeight,
    petWeightError,
    refreshPetsSuccess,
    saveButtonDisabled,
  } = useAddAPetModal({
    handleModalAddPetClose,
    isEditMode,
    modalAddPetVisible,
    petInformation,
  });

  useEffect(() => {
    if (petNameRef.current) {
      petNameRef.current.focus();
    }
  }, [petNameRef]);

  const petTypeSelectorValues = {
    left: {
      icon: DogIcon,
      id: PET_TYPE_ID.DOG,
      title: t('AddPetModal.dog'),
    },
    right: {
      icon: CatIcon,
      id: PET_TYPE_ID.CAT,
      title: t('AddPetModal.cat'),
    },
  };

  const genderSelectorValues = {
    left: {
      icon: null,
      id: PET_GENDER.MALE,
      title: t('AddPetModal.genderMale'),
    },
    right: {
      icon: null,
      id: PET_GENDER.FEMALE,
      title: t('AddPetModal.genderFemale'),
    },
  };

  async function handleSavePetSuccess() {
    if (myPets.addedPetId) {
      sendEvent(EV10({
        breedId: petBreedSelected.Id,
        breedName: petBreedSelected.Name,
        petDateOfBirth: petBirthdaySelected.toISOString().split('T')[0],
        petDescription: petBio.trim(),
        petGender: petGenderSelected ? 'Female' : 'Male',
        petId: myPets.addedPetId,
        petImageSelected: 'No',
        petName,
        petSpecies: petTypeSelected ? 'Cat' : 'Dog',
        petWeight,
      }));
    }

    onCloseModalAddPet();
    refreshPetsSuccess();
  }

  const handleEditContactSupport = () => {
    handleModalAddPetClose();
    onContactUsClick();
  };

  return (
    <ModalContainer show={modalAddPetVisible}>
      <ScrollableDiv className="Add-a-pet-modal-main-container" tabIndex={-1}>
        <div className="Add-a-pet-title-container">
          <h3>{modalTitle}</h3>

          <button
            className="Add-a-pet-modal-close-icon-container"
            id="closeIconAddPetModal"
            onClick={handleModalAddPetClose}
            type="button"
          >
            <img
              alt="Close"
              className="Add-a-pet-modal-close-icon"
              src={closeIcon}
            />
          </button>
        </div>

        {isPetInsured && (
          <div className="Add-a-pet-modal-edit-message">
            <img
              alt="Info"
              className="Add-a-pet-modal-edit-message-icon"
              src={infoIcon}
            />

            <div className="Add-a-pet-modal-edit-message-texts-wrapper">
              <h4 className="Add-a-pet-modal-edit-message-title">
                {t('AddAPetModal.editContactTitle')}
              </h4>

              <button
                className="Add-a-pet-modal-edit-message-button"
                onClick={handleEditContactSupport}
                type="button"
              >
                {t('AddAPetModal.editContactButton')}
              </button>
            </div>
          </div>
        )}

        <p className="Add-a-pet-modal-description">
          {t('AddAPetModal.description')}
        </p>

        <div className="Add-a-pet-modal-content-container">
          <PetCloudInput
            ariaProps={{ 'aria-label': t('AddAPetModal.petNameField') }}
            className="Add-a-pet-modal-pet-name"
            disabled={isPetInsured}
            error={petNameError}
            inputRef={petNameRef}
            maxLength={70}
            onBlur={handlePetNameOnBlur}
            onChange={handlePetNameChanged}
            placeholder={t('AddAPetModal.petNameField')}
            title={t('AddAPetModal.petNameField')}
            value={petName}
          />

          <div className="Add-a-pet-row-container">
            <GroupButtonSelector
              disabled={isPetInsured || isEditMode}
              error={petTypeError}
              onSelectionChange={handlePetTypeSelectedChanged}
              selectedValue={petTypeSelected}
              title={t('AddAPetModal.petTypeTitle')}
              values={petTypeSelectorValues}
            />

            <GroupButtonSelector
              disabled={isPetInsured && !isGPI}
              error={petGenderError}
              labelRef={petGenderRef}
              onSelectionChange={handlePetGenderSelectionChange}
              selectedValue={petGenderSelected}
              title={t('AddAPetModal.petGenderTitle')}
              values={genderSelectorValues}
            />
          </div>

          <div className="Add-a-pet-modal-pet-breed-wrapper">
            <FigoSelectSearchable
              ariaProps={{ 'aria-label': t('AddAPetModal.breedSelector') }}
              arrowIcon={arrowIcon}
              classNameContainer="Add-a-pet-modal-pet-breed-container"
              disabled={isPetInsured}
              elementDisplayName="Name"
              elementValue="Id"
              errorMessage={petBreedError}
              iconClassName="Add-a-pet-modal-pet-breed-icon"
              items={petBreedList}
              onSelectionChanged={handlePetBreedSelectionChanged}
              placeholder={t('AddAPetModal.select')}
              placeholderClassname="Add-a-pet-modal-input-placeholder"
              selectedValue={petBreedSelected}
              title={t('AddAPetModal.petBreedTitle')}
            />
          </div>

          <div className="Add-a-pet-row-container">
            <AgeInputSelector
              error={petBirthdayError}
              handleInputError={onBirthdaySelectorBlur}
              onDatePickerSelectionChange={onDatePickerChange}
              selectedDate={petBirthdaySelected}
            />

            <PetCloudInput
              ariaProps={{ 'aria-label': t('AddAPetModal.petWeightTitle') }}
              className="Add-a-pet-modal-pet-weight"
              error={petWeightError}
              inputRef={petWeightRef}
              onBlur={handlePetWeightOnBlur}
              onChange={handlePetWeightChanged}
              onFocus={handlePetWeightOnFocus}
              placeholder={t('AddAPetModal.pounds')}
              title={t('AddAPetModal.petWeightTitle')}
              value={petWeight}
            />
          </div>

          <PetCloudInput
            ariaProps={{ 'aria-label': t('AddAPetModal.petBioTitle') }}
            className="Add-a-pet-modal-pet-bio"
            inputRef={petBioRef}
            isTextArea
            maxLength={PET_BIO_MAX_LENGTH}
            onChange={handlePetsBioChange}
            optionalText={t('AddAPetModal.optional')}
            placeholder={t('AddAPetModal.petBioPlaceholder')}
            title={t('AddAPetModal.petBioTitle')}
            value={petBio}
          />

          <span aria-live="polite" className="Add-a-pet-modal-error">
            {myPets.error && t('AddAPetModal.addPetError')}
          </span>
        </div>

        {isEditMode && !isPetInsured && (
          <div className="Add-a-pet-modal-delete-container">
            <button
              className="Add-a-pet-modal-delete"
              onClick={onDelete}
              type="button"
            >
              {t('AddPetModal.delete')}
            </button>
          </div>
        )}

        <div className="Add-a-pet-modal-footer-container" tabIndex={-1}>
          <ActionButton
            disabled={saveButtonDisabled}
            loading={myPets.loading}
            onClick={handleSavePet}
            onSuccess={handleSavePetSuccess}
            success={myPets.success}
            title={isEditMode ? t('AddAPetModal.saveButton')
              : t('AddAPetModal.addButton')}
          />

          <ActionButton
            isSecondaryButton
            onClick={handleModalAddPetClose}
            title={t('AddAPetModal.cancelButton')}
          />
        </div>
      </ScrollableDiv>
    </ModalContainer>
  );
};

export { AddAPetModal };
